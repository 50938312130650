//
// Copyright 2024 DXOS.org
//

export type PeerId = string & { __peerId: true };

export type JoinProtocolMessage = {
  type: 'join';
  targetId?: PeerId;
  senderId: PeerId;
  supportedProtocolVersions: string;
  metadata?: Record<string, unknown>;
};

export type LeaveProtocolMessage = {
  type: 'leave';
  targetId?: PeerId;
  senderId: PeerId;
};

export type PeerProtocolMessage = {
  type: 'peer';
  senderId: PeerId;
  targetId: PeerId;
  selectedProtocolVersion: string;
  metadata?: Record<string, unknown>;
};

/**
 * Sent by a {@link Repo} to request a document from a peer.
 *
 * @remarks
 * This is identical to a {@link SyncMessage} except that it is sent by a {@link Repo}
 * as the initial sync message when asking the other peer if it has the document.
 */
export type RequestProtocolMessage = {
  type: 'request';
  senderId: PeerId;
  targetId: PeerId;
  documentId: string;
  data: Uint8Array;
};

/**
 * A sync message for a particular document
 */
export type SyncProtocolMessage = {
  type: 'sync';
  senderId: PeerId;
  targetId: PeerId;
  documentId: string;
  data: Uint8Array;
};

/**
 * Sent by a {@link Repo} to indicate that it does not have the document and none of its connected
 * peers do either.
 */
export type UnavailableProtocolMessage = {
  type: 'doc-unavailable';
  senderId: PeerId;
  targetId: PeerId;
  documentId: string;
};

/**
 * An ephemeral message.
 *
 * @remarks
 * Ephemeral messages are not persisted anywhere. The data property can be used by the application
 * as needed. The repo gossips these around.
 *
 * In order to avoid infinite loops of ephemeral messages, every message has (a) a session ID, which
 * is a random number generated by the sender at startup time; and (b) a sequence number. The
 * combination of these two things allows us to discard messages we have already seen.
 * */
export type EphemeralProtocolMessage = {
  type: 'ephemeral';
  senderId: PeerId;
  targetId: PeerId;
  count: number;
  sessionId: string;
  documentId: string;
  data: Uint8Array;
};

export type ErrorProtocolMessage = {
  type: 'error';
  senderId?: PeerId;
  targetId?: PeerId;
  message: string;
};

export const MESSAGE_TYPE_COLLECTION_QUERY = 'collection-query';

export type CollectionQueryMessage = {
  type: typeof MESSAGE_TYPE_COLLECTION_QUERY;
  senderId: PeerId;
  targetId: PeerId;
  collectionId: string;

  /**
   * Identifier of the current state.
   * Remote peer will skip sending the state if it has the same tag.
   */
  stateTag?: string;
};

export const MESSAGE_TYPE_COLLECTION_STATE = 'collection-state';

export type CollectionStateMessage = {
  type: typeof MESSAGE_TYPE_COLLECTION_STATE;
  senderId: PeerId;
  targetId: PeerId;
  collectionId: string;

  /**
   *  State representation is implementation-defined.
   */
  state?: unknown;

  /**
   * Identifier of the current state.
   */
  stateTag?: string;
};

export type AutomergeProtocolMessage =
  | JoinProtocolMessage
  | PeerProtocolMessage
  | LeaveProtocolMessage
  | RequestProtocolMessage
  | SyncProtocolMessage
  | UnavailableProtocolMessage
  | EphemeralProtocolMessage
  | ErrorProtocolMessage
  | CollectionQueryMessage
  | CollectionStateMessage;
