import type { Stream, RequestOptions } from "@dxos/codec-protobuf";
import substitutions from "../../../substitutions.js";
import * as dxos_agent_dashboard from "../agent/dashboard.js";
import * as dxos_agent_echoproxy from "../agent/echoproxy.js";
import * as dxos_agent_epoch from "../agent/epoch.js";
import * as dxos_agent_functions from "../agent/functions.js";
import * as dxos_bot from "../bot.js";
import * as dxos_client_services from "../client/services.js";
import * as dxos_config from "../config.js";
import * as dxos_devtools_host from "../devtools/host.js";
import * as dxos_devtools_swarm from "../devtools/swarm.js";
import * as dxos_echo_blob from "../echo/blob.js";
import * as dxos_echo_feed from "../echo/feed.js";
import * as dxos_echo_filter from "../echo/filter.js";
import * as dxos_echo_indexing from "../echo/indexing.js";
import * as dxos_echo_metadata from "../echo/metadata.js";
import * as dxos_echo_model_document from "../echo/model/document.js";
import * as dxos_echo_model_messenger from "../echo/model/messenger.js";
import * as dxos_echo_model_text from "../echo/model/text.js";
import * as dxos_echo_object from "../echo/object.js";
import * as dxos_echo_query from "../echo/query.js";
import * as dxos_echo_service from "../echo/service.js";
import * as dxos_echo_snapshot from "../echo/snapshot.js";
import * as dxos_echo_timeframe from "../echo/timeframe.js";
import * as dxos_edge_messenger from "../edge/messenger.js";
import * as dxos_error from "../error.js";
import * as dxos_gravity from "../gravity.js";
import * as dxos_halo_credentials from "../halo/credentials.js";
import * as dxos_halo_credentials_auth from "../halo/credentials/auth.js";
import * as dxos_halo_credentials_greet from "../halo/credentials/greet.js";
import * as dxos_halo_credentials_identity from "../halo/credentials/identity.js";
import * as dxos_halo_invitations from "../halo/invitations.js";
import * as dxos_halo_keyring from "../halo/keyring.js";
import * as dxos_halo_keys from "../halo/keys.js";
import * as dxos_halo_signed from "../halo/signed.js";
import * as dxos_iframe from "../iframe.js";
import * as dxos_keys from "../keys.js";
import * as dxos_mesh_broadcast from "./broadcast.js";
import * as dxos_mesh_messaging from "./messaging.js";
import * as dxos_mesh_muxer from "./muxer.js";
import * as dxos_mesh_presence from "./presence.js";
import * as dxos_mesh_protocol from "./protocol.js";
import * as dxos_mesh_replicator from "./replicator.js";
import * as dxos_mesh_signal from "./signal.js";
import * as dxos_mesh_swarm from "./swarm.js";
import * as dxos_mesh_teleport from "./teleport.js";
import * as dxos_mesh_teleport_auth from "./teleport/auth.js";
import * as dxos_mesh_teleport_automerge from "./teleport/automerge.js";
import * as dxos_mesh_teleport_blobsync from "./teleport/blobsync.js";
import * as dxos_mesh_teleport_control from "./teleport/control.js";
import * as dxos_mesh_teleport_gossip from "./teleport/gossip.js";
import * as dxos_mesh_teleport_notarization from "./teleport/notarization.js";
import * as dxos_mesh_teleport_replicator from "./teleport/replicator.js";
import * as dxos_registry from "../registry.js";
import * as dxos_rpc from "../rpc.js";
import * as dxos_service_agentmanager from "../service/agentmanager.js";
import * as dxos_service_publisher from "../service/publisher.js";
import * as dxos_service_supervisor from "../service/supervisor.js";
import * as dxos_service_tunnel from "../service/tunnel.js";
import * as dxos_tracing from "../tracing.js";
import * as dxos_type from "../type.js";
import * as dxos_value from "../value.js";
import * as example_testing_data from "../../example/testing/data.js";
import * as example_testing_rpc from "../../example/testing/rpc.js";
import * as google_protobuf from "../../google/protobuf.js";
/**
 * Defined in:
 *   {@link file://./../../../dxos/mesh/bridge.proto}
 */
export interface BridgeService {
    open: (request: ConnectionRequest, options?: RequestOptions) => Stream<BridgeEvent>;
    sendSignal: (request: SignalRequest, options?: RequestOptions) => Promise<void>;
    sendData: (request: DataRequest, options?: RequestOptions) => Promise<void>;
    close: (request: CloseRequest, options?: RequestOptions) => Promise<void>;
    getDetails: (request: DetailsRequest, options?: RequestOptions) => Promise<DetailsResponse>;
    getStats: (request: StatsRequest, options?: RequestOptions) => Promise<StatsResponse>;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/mesh/bridge.proto}
 */
export enum ConnectionState {
    INVALID = 0,
    CONNECTING = 1,
    CONNECTED = 2,
    CLOSED = 3
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/mesh/bridge.proto}
 */
export interface BridgeEvent {
    connection?: BridgeEvent.ConnectionEvent;
    signal?: BridgeEvent.SignalEvent;
    data?: BridgeEvent.DataEvent;
}
export namespace BridgeEvent {
    /**
     * Defined in:
     *   {@link file://./../../../dxos/mesh/bridge.proto}
     */
    export interface ConnectionEvent {
        state: ConnectionState;
        /**
         * Options:
         *   - proto3_optional = true
         */
        error?: string;
    }
    /**
     * Defined in:
     *   {@link file://./../../../dxos/mesh/bridge.proto}
     */
    export interface SignalEvent {
        payload: dxos_mesh_swarm.Signal;
    }
    /**
     * Defined in:
     *   {@link file://./../../../dxos/mesh/bridge.proto}
     */
    export interface DataEvent {
        payload: Uint8Array;
    }
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/mesh/bridge.proto}
 */
export interface ConnectionRequest {
    proxyId: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
    initiator: boolean;
    remotePeerKey: string;
    ownPeerKey: string;
    topic: string;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/mesh/bridge.proto}
 */
export interface SignalRequest {
    proxyId: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
    signal: dxos_mesh_swarm.Signal;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/mesh/bridge.proto}
 */
export interface DataRequest {
    proxyId: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
    payload: Uint8Array;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/mesh/bridge.proto}
 */
export interface CloseRequest {
    proxyId: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/mesh/bridge.proto}
 */
export interface DetailsRequest {
    proxyId: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/mesh/bridge.proto}
 */
export interface DetailsResponse {
    details: string;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/mesh/bridge.proto}
 */
export interface StatsRequest {
    proxyId: ReturnType<(typeof substitutions)["dxos.keys.PublicKey"]["decode"]>;
}
/**
 * Defined in:
 *   {@link file://./../../../dxos/mesh/bridge.proto}
 */
export interface StatsResponse {
    stats: ReturnType<(typeof substitutions)["google.protobuf.Struct"]["decode"]>;
}
