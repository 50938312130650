import type { Stream, RequestOptions } from "@dxos/codec-protobuf";
import substitutions from "../../substitutions.js";
import * as dxos_agent_dashboard from "./agent/dashboard.js";
import * as dxos_agent_echoproxy from "./agent/echoproxy.js";
import * as dxos_agent_epoch from "./agent/epoch.js";
import * as dxos_agent_functions from "./agent/functions.js";
import * as dxos_bot from "./bot.js";
import * as dxos_client_services from "./client/services.js";
import * as dxos_config from "./config.js";
import * as dxos_devtools_host from "./devtools/host.js";
import * as dxos_devtools_swarm from "./devtools/swarm.js";
import * as dxos_echo_blob from "./echo/blob.js";
import * as dxos_echo_feed from "./echo/feed.js";
import * as dxos_echo_filter from "./echo/filter.js";
import * as dxos_echo_indexing from "./echo/indexing.js";
import * as dxos_echo_metadata from "./echo/metadata.js";
import * as dxos_echo_model_document from "./echo/model/document.js";
import * as dxos_echo_model_messenger from "./echo/model/messenger.js";
import * as dxos_echo_model_text from "./echo/model/text.js";
import * as dxos_echo_object from "./echo/object.js";
import * as dxos_echo_query from "./echo/query.js";
import * as dxos_echo_service from "./echo/service.js";
import * as dxos_echo_snapshot from "./echo/snapshot.js";
import * as dxos_echo_timeframe from "./echo/timeframe.js";
import * as dxos_edge_messenger from "./edge/messenger.js";
import * as dxos_error from "./error.js";
import * as dxos_gravity from "./gravity.js";
import * as dxos_halo_credentials from "./halo/credentials.js";
import * as dxos_halo_credentials_auth from "./halo/credentials/auth.js";
import * as dxos_halo_credentials_greet from "./halo/credentials/greet.js";
import * as dxos_halo_credentials_identity from "./halo/credentials/identity.js";
import * as dxos_halo_invitations from "./halo/invitations.js";
import * as dxos_halo_keyring from "./halo/keyring.js";
import * as dxos_halo_keys from "./halo/keys.js";
import * as dxos_halo_signed from "./halo/signed.js";
import * as dxos_iframe from "./iframe.js";
import * as dxos_keys from "./keys.js";
import * as dxos_mesh_bridge from "./mesh/bridge.js";
import * as dxos_mesh_broadcast from "./mesh/broadcast.js";
import * as dxos_mesh_messaging from "./mesh/messaging.js";
import * as dxos_mesh_muxer from "./mesh/muxer.js";
import * as dxos_mesh_presence from "./mesh/presence.js";
import * as dxos_mesh_protocol from "./mesh/protocol.js";
import * as dxos_mesh_replicator from "./mesh/replicator.js";
import * as dxos_mesh_signal from "./mesh/signal.js";
import * as dxos_mesh_swarm from "./mesh/swarm.js";
import * as dxos_mesh_teleport from "./mesh/teleport.js";
import * as dxos_mesh_teleport_auth from "./mesh/teleport/auth.js";
import * as dxos_mesh_teleport_automerge from "./mesh/teleport/automerge.js";
import * as dxos_mesh_teleport_blobsync from "./mesh/teleport/blobsync.js";
import * as dxos_mesh_teleport_control from "./mesh/teleport/control.js";
import * as dxos_mesh_teleport_gossip from "./mesh/teleport/gossip.js";
import * as dxos_mesh_teleport_notarization from "./mesh/teleport/notarization.js";
import * as dxos_mesh_teleport_replicator from "./mesh/teleport/replicator.js";
import * as dxos_registry from "./registry.js";
import * as dxos_service_agentmanager from "./service/agentmanager.js";
import * as dxos_service_publisher from "./service/publisher.js";
import * as dxos_service_supervisor from "./service/supervisor.js";
import * as dxos_service_tunnel from "./service/tunnel.js";
import * as dxos_tracing from "./tracing.js";
import * as dxos_type from "./type.js";
import * as dxos_value from "./value.js";
import * as example_testing_data from "../example/testing/data.js";
import * as example_testing_rpc from "../example/testing/rpc.js";
import * as google_protobuf from "../google/protobuf.js";
/**
 * Defined in:
 *   {@link file://./../../dxos/rpc.proto}
 */
export interface RpcMessage {
    request?: Request;
    response?: Response;
    /**
     * Means that the node is trying to open the connection.
     */
    open?: boolean;
    /**
     * Means that the node has received the "open" message and is ready to perform requests.
     */
    openAck?: boolean;
    streamClose?: StreamClose;
    /**
     * Request to close the connection.
     */
    bye?: Bye;
}
/**
 * Defined in:
 *   {@link file://./../../dxos/rpc.proto}
 */
export interface Request {
    id: number;
    method: string;
    payload: ReturnType<(typeof substitutions)["google.protobuf.Any"]["decode"]>;
    stream: boolean;
}
/**
 * Defined in:
 *   {@link file://./../../dxos/rpc.proto}
 */
export interface Response {
    id: number;
    payload?: ReturnType<(typeof substitutions)["google.protobuf.Any"]["decode"]>;
    error?: dxos_error.Error;
    /**
     * Sent when stream is closed without an error.
     */
    close?: boolean;
    /**
     * Sent when the server has processed a request with a streaming response.
     * Can be skipped by the server.
     * In this case the first payload should be treated as the server being ready.
     */
    streamReady?: boolean;
}
/**
 * Defined in:
 *   {@link file://./../../dxos/rpc.proto}
 */
export interface StreamClose {
    id: number;
}
/**
 * Defined in:
 *   {@link file://./../../dxos/rpc.proto}
 */
export interface MessageTrace {
    direction: MessageTrace.Direction;
    data: Uint8Array;
}
export namespace MessageTrace {
    /**
     * Defined in:
     *   {@link file://./../../dxos/rpc.proto}
     */
    export enum Direction {
        INCOMING = 0,
        OUTGOING = 1
    }
}
/**
 * Request to close the connection.
 * Connection is closed once both sides have received the Bye message.
 *
 * Defined in:
 *   {@link file://./../../dxos/rpc.proto}
 */
export interface Bye {
}
