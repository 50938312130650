//
// Copyright 2024 DXOS.org
//

import {
  createProxy,
  getMeta,
  getProxyHandler,
  getProxySlot,
  getProxyTarget,
  getSchema,
  isReactiveObject,
  requireTypeReference,
  type BaseObject,
  type HasId,
  MutableSchema,
  type ObjectMeta,
  type ReactiveObject,
  type S,
  SchemaValidator,
} from '@dxos/echo-schema';
import { compositeRuntime } from '@dxos/echo-signals/runtime';
import { invariant } from '@dxos/invariant';
import { ComplexMap, deepMapValues } from '@dxos/util';

import { DATA_NAMESPACE, PROPERTY_ID, EchoReactiveHandler, throwIfCustomClass } from './echo-handler';
import {
  type ObjectInternals,
  type ProxyTarget,
  symbolInternals,
  symbolNamespace,
  symbolPath,
} from './echo-proxy-target';
import { type DecodedAutomergePrimaryValue, ObjectCore } from '../core-db';
import { type EchoDatabase } from '../proxy-db';

// TODO(burdon): Rename EchoObject and reconcile with proto name.
export type ReactiveEchoObject<T extends BaseObject> = ReactiveObject<T> & HasId;

export const isEchoObject = (value: unknown): value is ReactiveEchoObject<any> =>
  isReactiveObject(value) && getProxyHandler(value) instanceof EchoReactiveHandler;

/**
 * Creates a reactive ECHO object.
 * @internal
 */
// TODO(burdon): Document lifecycle.
export const createObject = <T extends BaseObject>(obj: T): ReactiveEchoObject<T> => {
  invariant(!isEchoObject(obj));
  const schema = getSchema(obj);
  if (schema != null) {
    validateSchema(schema);
  }
  validateInitialProps(obj);

  const core = new ObjectCore();
  if (isReactiveObject(obj)) {
    // Already an echo-schema reactive object.
    const meta = getProxyTarget<ObjectMeta>(getMeta(obj));

    // TODO(burdon): Requires comment.
    const slot = getProxySlot(obj);
    slot.setHandler(EchoReactiveHandler.instance);

    const target = slot.target as ProxyTarget;
    target[symbolInternals] = initInternals(core);
    target[symbolPath] = [];
    target[symbolNamespace] = DATA_NAMESPACE;
    slot.handler._proxyMap.set(target, obj);

    target[symbolInternals].subscriptions.push(core.updates.on(() => target[symbolInternals].signal.notifyWrite()));

    // NOTE: This call is recursively linking all nested objects
    //  which can cause recursive loops of `createObject` if `EchoReactiveHandler` is not set prior to this call.
    //  Do not change order.
    initCore(core, target);
    slot.handler.init(target);

    setTypeOnObject(target[symbolInternals], schema);
    if (meta && meta.keys.length > 0) {
      target[symbolInternals].core.setMeta(meta);
    }

    return obj as any;
  } else {
    const target: ProxyTarget = {
      [symbolInternals]: initInternals(core),
      [symbolPath]: [],
      [symbolNamespace]: DATA_NAMESPACE,
      ...(obj as any),
    };

    target[symbolInternals].subscriptions.push(core.updates.on(() => target[symbolInternals].signal.notifyWrite()));

    initCore(core, target);
    const proxy = createProxy<ProxyTarget>(target, EchoReactiveHandler.instance) as any;
    setTypeOnObject(target[symbolInternals], schema);
    return proxy;
  }
};

// TODO(burdon): Call and remove subscriptions.
export const destroyObject = <T extends BaseObject>(proxy: ReactiveEchoObject<T>) => {
  invariant(isEchoObject(proxy));
  const target: ProxyTarget = getProxyTarget(proxy);
  const internals: ObjectInternals = target[symbolInternals];
  for (const unsubscribe of internals.subscriptions) {
    unsubscribe();
  }
};

const initCore = (core: ObjectCore, target: ProxyTarget) => {
  // Handle ID pre-generated by `create`.
  if (PROPERTY_ID in target) {
    target[symbolInternals].core.id = target[PROPERTY_ID];
    delete target[PROPERTY_ID];
  }

  core.initNewObject(linkAllNestedProperties(target));
};

/**
 * @internal
 */
export const initEchoReactiveObjectRootProxy = (core: ObjectCore, database?: EchoDatabase): ReactiveEchoObject<any> => {
  const target: ProxyTarget = {
    [symbolInternals]: initInternals(core, database),
    [symbolPath]: [],
    [symbolNamespace]: DATA_NAMESPACE,
  };

  // TODO(dmaretskyi): Does this need to be disposed?
  core.updates.on(() => target[symbolInternals].signal.notifyWrite());

  return createProxy<ProxyTarget>(target, EchoReactiveHandler.instance) as any;
};

const validateSchema = (schema: S.Schema<any>) => {
  requireTypeReference(schema);
  SchemaValidator.validateSchema(schema);
};

const setTypeOnObject = (internals: ObjectInternals, schema: S.Schema<any> | undefined) => {
  if (schema != null) {
    internals.core.setType(requireTypeReference(schema));
  }
};

const validateInitialProps = (target: any, seen: Set<object> = new Set()) => {
  if (seen.has(target)) {
    return;
  }

  seen.add(target);
  for (const key in target) {
    const value = target[key];
    if (value === undefined) {
      delete target[key];
    } else if (typeof value === 'object') {
      if (value instanceof MutableSchema) {
        target[key] = value.storedSchema;
        validateInitialProps(value.storedSchema, seen);
      } else if (!isEchoObject(value)) {
        throwIfCustomClass(key, value);
        validateInitialProps(target[key], seen);
      }
    }
  }
};

const linkAllNestedProperties = (target: ProxyTarget): DecodedAutomergePrimaryValue => {
  return deepMapValues(target, (value, recurse) => {
    if (isReactiveObject(value) as boolean) {
      return EchoReactiveHandler.instance.createRef(target, value);
    }

    return recurse(value);
  });
};

const initInternals = (core: ObjectCore, database?: EchoDatabase): ObjectInternals => ({
  core,
  targetsMap: new ComplexMap((key) => JSON.stringify(key)),
  signal: compositeRuntime.createSignal(),
  linkCache: new Map<string, ReactiveEchoObject<any>>(),
  database,
  subscriptions: [],
});
