import {
  __export
} from "./chunk-MLKGABMK.js";

// ../../../../node_modules/.pnpm/@automerge+automerge@2.2.2/node_modules/@automerge/automerge/dist/mjs/uuid.js
import { v4 } from "uuid";
function defaultFactory() {
  return v4().replace(/-/g, "");
}
var factory = defaultFactory;
var uuid = () => {
  return factory();
};
uuid.setFactory = (newFactory) => {
  factory = newFactory;
};
uuid.reset = () => {
  factory = defaultFactory;
};

// ../../../../node_modules/.pnpm/@automerge+automerge@2.2.2/node_modules/@automerge/automerge/dist/mjs/constants.js
var STATE = Symbol.for("_am_meta");
var TRACE = Symbol.for("_am_trace");
var OBJECT_ID = Symbol.for("_am_objectId");
var IS_PROXY = Symbol.for("_am_isProxy");
var CLEAR_CACHE = Symbol.for("_am_clearCache");
var UINT = Symbol.for("_am_uint");
var INT = Symbol.for("_am_int");
var F64 = Symbol.for("_am_f64");
var COUNTER = Symbol.for("_am_counter");
var TEXT = Symbol.for("_am_text");

// ../../../../node_modules/.pnpm/@automerge+automerge@2.2.2/node_modules/@automerge/automerge/dist/mjs/text.js
var Text = class _Text {
  constructor(text) {
    if (typeof text === "string") {
      this.elems = [...text];
    } else if (Array.isArray(text)) {
      this.elems = text;
    } else if (text === void 0) {
      this.elems = [];
    } else {
      throw new TypeError(`Unsupported initial value for Text: ${text}`);
    }
    Reflect.defineProperty(this, TEXT, { value: true });
  }
  get length() {
    return this.elems.length;
  }
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  get(index) {
    return this.elems[index];
  }
  /**
   * Iterates over the text elements character by character, including any
   * inline objects.
   */
  [Symbol.iterator]() {
    const elems = this.elems;
    let index = -1;
    return {
      next() {
        index += 1;
        if (index < elems.length) {
          return { done: false, value: elems[index] };
        } else {
          return { done: true };
        }
      }
    };
  }
  /**
   * Returns the content of the Text object as a simple string, ignoring any
   * non-character elements.
   */
  toString() {
    if (!this.str) {
      this.str = "";
      for (const elem of this.elems) {
        if (typeof elem === "string")
          this.str += elem;
        else
          this.str += "\uFFFC";
      }
    }
    return this.str;
  }
  /**
   * Returns the content of the Text object as a sequence of strings,
   * interleaved with non-character elements.
   *
   * For example, the value `['a', 'b', {x: 3}, 'c', 'd']` has spans:
   * `=> ['ab', {x: 3}, 'cd']`
   */
  toSpans() {
    if (!this.spans) {
      this.spans = [];
      let chars = "";
      for (const elem of this.elems) {
        if (typeof elem === "string") {
          chars += elem;
        } else {
          if (chars.length > 0) {
            this.spans.push(chars);
            chars = "";
          }
          this.spans.push(elem);
        }
      }
      if (chars.length > 0) {
        this.spans.push(chars);
      }
    }
    return this.spans;
  }
  /**
   * Returns the content of the Text object as a simple string, so that the
   * JSON serialization of an Automerge document represents text nicely.
   */
  toJSON() {
    return this.toString();
  }
  /**
   * Updates the list item at position `index` to a new value `value`.
   */
  set(index, value) {
    if (this[STATE]) {
      throw new RangeError("object cannot be modified outside of a change block");
    }
    this.elems[index] = value;
  }
  /**
   * Inserts new list items `values` starting at position `index`.
   */
  insertAt(index, ...values) {
    if (this[STATE]) {
      throw new RangeError("object cannot be modified outside of a change block");
    }
    if (values.every((v) => typeof v === "string")) {
      this.elems.splice(index, 0, ...values.join(""));
    } else {
      this.elems.splice(index, 0, ...values);
    }
  }
  /**
   * Deletes `numDelete` list items starting at position `index`.
   * if `numDelete` is not given, one item is deleted.
   */
  deleteAt(index, numDelete = 1) {
    if (this[STATE]) {
      throw new RangeError("object cannot be modified outside of a change block");
    }
    this.elems.splice(index, numDelete);
  }
  map(callback) {
    this.elems.map(callback);
  }
  lastIndexOf(searchElement, fromIndex) {
    this.elems.lastIndexOf(searchElement, fromIndex);
  }
  concat(other) {
    return new _Text(this.elems.concat(other.elems));
  }
  every(test) {
    return this.elems.every(test);
  }
  filter(test) {
    return new _Text(this.elems.filter(test));
  }
  find(test) {
    return this.elems.find(test);
  }
  findIndex(test) {
    return this.elems.findIndex(test);
  }
  forEach(f) {
    this.elems.forEach(f);
  }
  includes(elem) {
    return this.elems.includes(elem);
  }
  indexOf(elem) {
    return this.elems.indexOf(elem);
  }
  join(sep) {
    return this.elems.join(sep);
  }
  reduce(f) {
    this.elems.reduce(f);
  }
  reduceRight(f) {
    this.elems.reduceRight(f);
  }
  slice(start, end) {
    return new _Text(this.elems.slice(start, end));
  }
  some(test) {
    return this.elems.some(test);
  }
  toLocaleString() {
    this.toString();
  }
};

// ../../../../node_modules/.pnpm/@automerge+automerge@2.2.2/node_modules/@automerge/automerge/dist/mjs/counter.js
var Counter = class {
  constructor(value) {
    this.value = value || 0;
    Reflect.defineProperty(this, COUNTER, { value: true });
  }
  /**
   * A peculiar JavaScript language feature from its early days: if the object
   * `x` has a `valueOf()` method that returns a number, you can use numerical
   * operators on the object `x` directly, such as `x + 1` or `x < 4`.
   * This method is also called when coercing a value to a string by
   * concatenating it with another string, as in `x + ''`.
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/valueOf
   */
  valueOf() {
    return this.value;
  }
  /**
   * Returns the counter value as a decimal string. If `x` is a counter object,
   * this method is called e.g. when you do `['value: ', x].join('')` or when
   * you use string interpolation: `value: ${x}`.
   */
  toString() {
    return this.valueOf().toString();
  }
  /**
   * Returns the counter value, so that a JSON serialization of an Automerge
   * document represents the counter simply as an integer.
   */
  toJSON() {
    return this.value;
  }
  /**
   * Increases the value of the counter by `delta`. If `delta` is not given,
   * increases the value of the counter by 1.
   *
   * Will throw an error if used outside of a change callback.
   */
  increment(_delta) {
    throw new Error("Counters should not be incremented outside of a change callback");
  }
  /**
   * Decreases the value of the counter by `delta`. If `delta` is not given,
   * decreases the value of the counter by 1.
   *
   * Will throw an error if used outside of a change callback.
   */
  decrement(_delta) {
    throw new Error("Counters should not be decremented outside of a change callback");
  }
};
var WriteableCounter = class extends Counter {
  constructor(value, context2, path, objectId, key) {
    super(value);
    this.context = context2;
    this.path = path;
    this.objectId = objectId;
    this.key = key;
  }
  /**
   * Increases the value of the counter by `delta`. If `delta` is not given,
   * increases the value of the counter by 1.
   */
  increment(delta) {
    delta = typeof delta === "number" ? delta : 1;
    this.context.increment(this.objectId, this.key, delta);
    this.value += delta;
    return this.value;
  }
  /**
   * Decreases the value of the counter by `delta`. If `delta` is not given,
   * decreases the value of the counter by 1.
   */
  decrement(delta) {
    return this.increment(typeof delta === "number" ? -delta : -1);
  }
};
function getWriteableCounter(value, context2, path, objectId, key) {
  return new WriteableCounter(value, context2, path, objectId, key);
}

// ../../../../node_modules/.pnpm/@automerge+automerge@2.2.2/node_modules/@automerge/automerge/dist/mjs/raw_string.js
var RawString = class {
  constructor(val) {
    this.val = val;
  }
  /**
   * Returns the content of the RawString object as a simple string
   */
  toString() {
    return this.val;
  }
  toJSON() {
    return this.val;
  }
};

// ../../../../node_modules/.pnpm/@automerge+automerge@2.2.2/node_modules/@automerge/automerge/dist/mjs/numbers.js
var Int = class {
  constructor(value) {
    if (!(Number.isInteger(value) && value <= Number.MAX_SAFE_INTEGER && value >= Number.MIN_SAFE_INTEGER)) {
      throw new RangeError(`Value ${value} cannot be a uint`);
    }
    this.value = value;
    Reflect.defineProperty(this, INT, { value: true });
    Object.freeze(this);
  }
};
var Uint = class {
  constructor(value) {
    if (!(Number.isInteger(value) && value <= Number.MAX_SAFE_INTEGER && value >= 0)) {
      throw new RangeError(`Value ${value} cannot be a uint`);
    }
    this.value = value;
    Reflect.defineProperty(this, UINT, { value: true });
    Object.freeze(this);
  }
};
var Float64 = class {
  constructor(value) {
    if (typeof value !== "number") {
      throw new RangeError(`Value ${value} cannot be a float64`);
    }
    this.value = value || 0;
    Reflect.defineProperty(this, F64, { value: true });
    Object.freeze(this);
  }
};

// ../../../../node_modules/.pnpm/@automerge+automerge@2.2.2/node_modules/@automerge/automerge/dist/mjs/proxies.js
function parseListIndex(key) {
  if (typeof key === "string" && /^[0-9]+$/.test(key))
    key = parseInt(key, 10);
  if (typeof key !== "number") {
    return key;
  }
  if (key < 0 || isNaN(key) || key === Infinity || key === -Infinity) {
    throw new RangeError("A list index must be positive, but you passed " + key);
  }
  return key;
}
function valueAt(target, prop) {
  const { context: context2, objectId, path, textV2 } = target;
  const value = context2.getWithType(objectId, prop);
  if (value === null) {
    return;
  }
  const datatype = value[0];
  const val = value[1];
  switch (datatype) {
    case void 0:
      return;
    case "map":
      return mapProxy(context2, val, textV2, [...path, prop]);
    case "list":
      return listProxy(context2, val, textV2, [...path, prop]);
    case "text":
      if (textV2) {
        return context2.text(val);
      } else {
        return textProxy(context2, val, [
          ...path,
          prop
        ]);
      }
    case "str":
      return val;
    case "uint":
      return val;
    case "int":
      return val;
    case "f64":
      return val;
    case "boolean":
      return val;
    case "null":
      return null;
    case "bytes":
      return val;
    case "timestamp":
      return val;
    case "counter": {
      const counter = getWriteableCounter(val, context2, path, objectId, prop);
      return counter;
    }
    default:
      throw RangeError(`datatype ${datatype} unimplemented`);
  }
}
function import_value(value, textV2, path) {
  switch (typeof value) {
    case "object":
      if (value == null) {
        return [null, "null"];
      } else if (value[UINT]) {
        return [value.value, "uint"];
      } else if (value[INT]) {
        return [value.value, "int"];
      } else if (value[F64]) {
        return [value.value, "f64"];
      } else if (value[COUNTER]) {
        return [value.value, "counter"];
      } else if (value instanceof Date) {
        return [value.getTime(), "timestamp"];
      } else if (value instanceof RawString) {
        return [value.toString(), "str"];
      } else if (value instanceof Text) {
        return [value, "text"];
      } else if (value instanceof Uint8Array) {
        return [value, "bytes"];
      } else if (value instanceof Array) {
        return [value, "list"];
      } else if (Object.getPrototypeOf(value) === Object.getPrototypeOf({})) {
        return [value, "map"];
      } else if (isSameDocument(value, context)) {
        throw new RangeError("Cannot create a reference to an existing document object");
      } else {
        throw new RangeError(`Cannot assign unknown object: ${value}`);
      }
    case "boolean":
      return [value, "boolean"];
    case "number":
      if (Number.isInteger(value)) {
        return [value, "int"];
      } else {
        return [value, "f64"];
      }
    case "string":
      if (textV2) {
        return [value, "text"];
      } else {
        return [value, "str"];
      }
    default:
      throw new RangeError(`Unsupported type ${typeof value} for path ${printPath(path)}`);
  }
}
function isSameDocument(val, context2) {
  var _b, _c;
  if (val instanceof Date) {
    return false;
  }
  if (val && ((_c = (_b = val[STATE]) === null || _b === void 0 ? void 0 : _b.handle) === null || _c === void 0 ? void 0 : _c.__wbg_ptr) === context2.__wbg_ptr) {
    return true;
  }
  return false;
}
var MapHandler = {
  get(target, key) {
    const { context: context2, objectId, cache } = target;
    if (key === Symbol.toStringTag) {
      return target[Symbol.toStringTag];
    }
    if (key === OBJECT_ID)
      return objectId;
    if (key === IS_PROXY)
      return true;
    if (key === TRACE)
      return target.trace;
    if (key === STATE)
      return { handle: context2, textV2: target.textV2 };
    if (!cache[key]) {
      cache[key] = valueAt(target, key);
    }
    return cache[key];
  },
  set(target, key, val) {
    const { context: context2, objectId, path, textV2 } = target;
    target.cache = {};
    if (isSameDocument(val, context2)) {
      throw new RangeError("Cannot create a reference to an existing document object");
    }
    if (key === TRACE) {
      target.trace = val;
      return true;
    }
    if (key === CLEAR_CACHE) {
      return true;
    }
    const [value, datatype] = import_value(val, textV2, [...path, key]);
    switch (datatype) {
      case "list": {
        const list = context2.putObject(objectId, key, []);
        const proxyList = listProxy(context2, list, textV2, [...path, key]);
        for (let i = 0; i < value.length; i++) {
          proxyList[i] = value[i];
        }
        break;
      }
      case "text": {
        if (textV2) {
          assertString(value);
          context2.putObject(objectId, key, value);
        } else {
          assertText(value);
          const text = context2.putObject(objectId, key, "");
          const proxyText = textProxy(context2, text, [...path, key]);
          proxyText.splice(0, 0, ...value);
        }
        break;
      }
      case "map": {
        const map = context2.putObject(objectId, key, {});
        const proxyMap = mapProxy(context2, map, textV2, [...path, key]);
        for (const key2 in value) {
          proxyMap[key2] = value[key2];
        }
        break;
      }
      default:
        context2.put(objectId, key, value, datatype);
    }
    return true;
  },
  deleteProperty(target, key) {
    const { context: context2, objectId } = target;
    target.cache = {};
    context2.delete(objectId, key);
    return true;
  },
  has(target, key) {
    const value = this.get(target, key);
    return value !== void 0;
  },
  getOwnPropertyDescriptor(target, key) {
    const value = this.get(target, key);
    if (typeof value !== "undefined") {
      return {
        configurable: true,
        enumerable: true,
        value
      };
    }
  },
  ownKeys(target) {
    const { context: context2, objectId } = target;
    const keys = context2.keys(objectId);
    return [...new Set(keys)];
  }
};
var ListHandler = {
  get(target, index) {
    const { context: context2, objectId } = target;
    index = parseListIndex(index);
    if (index === Symbol.hasInstance) {
      return (instance) => {
        return Array.isArray(instance);
      };
    }
    if (index === Symbol.toStringTag) {
      return target[Symbol.toStringTag];
    }
    if (index === OBJECT_ID)
      return objectId;
    if (index === IS_PROXY)
      return true;
    if (index === TRACE)
      return target.trace;
    if (index === STATE)
      return { handle: context2 };
    if (index === "length")
      return context2.length(objectId);
    if (typeof index === "number") {
      return valueAt(target, index);
    } else {
      return listMethods(target)[index];
    }
  },
  set(target, index, val) {
    const { context: context2, objectId, path, textV2 } = target;
    index = parseListIndex(index);
    if (isSameDocument(val, context2)) {
      throw new RangeError("Cannot create a reference to an existing document object");
    }
    if (index === CLEAR_CACHE) {
      return true;
    }
    if (index === TRACE) {
      target.trace = val;
      return true;
    }
    if (typeof index == "string") {
      throw new RangeError("list index must be a number");
    }
    const [value, datatype] = import_value(val, textV2, [...path, index]);
    switch (datatype) {
      case "list": {
        let list;
        if (index >= context2.length(objectId)) {
          list = context2.insertObject(objectId, index, []);
        } else {
          list = context2.putObject(objectId, index, []);
        }
        const proxyList = listProxy(context2, list, textV2, [...path, index]);
        proxyList.splice(0, 0, ...value);
        break;
      }
      case "text": {
        if (textV2) {
          assertString(value);
          if (index >= context2.length(objectId)) {
            context2.insertObject(objectId, index, value);
          } else {
            context2.putObject(objectId, index, value);
          }
        } else {
          let text;
          assertText(value);
          if (index >= context2.length(objectId)) {
            text = context2.insertObject(objectId, index, "");
          } else {
            text = context2.putObject(objectId, index, "");
          }
          const proxyText = textProxy(context2, text, [...path, index]);
          proxyText.splice(0, 0, ...value);
        }
        break;
      }
      case "map": {
        let map;
        if (index >= context2.length(objectId)) {
          map = context2.insertObject(objectId, index, {});
        } else {
          map = context2.putObject(objectId, index, {});
        }
        const proxyMap = mapProxy(context2, map, textV2, [...path, index]);
        for (const key in value) {
          proxyMap[key] = value[key];
        }
        break;
      }
      default:
        if (index >= context2.length(objectId)) {
          context2.insert(objectId, index, value, datatype);
        } else {
          context2.put(objectId, index, value, datatype);
        }
    }
    return true;
  },
  deleteProperty(target, index) {
    const { context: context2, objectId } = target;
    index = parseListIndex(index);
    const elem = context2.get(objectId, index);
    if (elem != null && elem[0] == "counter") {
      throw new TypeError("Unsupported operation: deleting a counter from a list");
    }
    context2.delete(objectId, index);
    return true;
  },
  has(target, index) {
    const { context: context2, objectId } = target;
    index = parseListIndex(index);
    if (typeof index === "number") {
      return index < context2.length(objectId);
    }
    return index === "length";
  },
  getOwnPropertyDescriptor(target, index) {
    const { context: context2, objectId } = target;
    if (index === "length")
      return { writable: true, value: context2.length(objectId) };
    if (index === OBJECT_ID)
      return { configurable: false, enumerable: false, value: objectId };
    index = parseListIndex(index);
    const value = valueAt(target, index);
    return { configurable: true, enumerable: true, value };
  },
  getPrototypeOf(target) {
    return Object.getPrototypeOf(target);
  },
  ownKeys() {
    const keys = [];
    keys.push("length");
    return keys;
  }
};
var TextHandler = Object.assign({}, ListHandler, {
  get(target, index) {
    const { context: context2, objectId } = target;
    index = parseListIndex(index);
    if (index === Symbol.hasInstance) {
      return (instance) => {
        return Array.isArray(instance);
      };
    }
    if (index === Symbol.toStringTag) {
      return target[Symbol.toStringTag];
    }
    if (index === OBJECT_ID)
      return objectId;
    if (index === IS_PROXY)
      return true;
    if (index === TRACE)
      return target.trace;
    if (index === STATE)
      return { handle: context2 };
    if (index === "length")
      return context2.length(objectId);
    if (typeof index === "number") {
      return valueAt(target, index);
    } else {
      return textMethods(target)[index] || listMethods(target)[index];
    }
  },
  getPrototypeOf() {
    return Object.getPrototypeOf(new Text());
  }
});
function mapProxy(context2, objectId, textV2, path) {
  const target = {
    context: context2,
    objectId,
    path: path || [],
    cache: {},
    textV2
  };
  const proxied = {};
  Object.assign(proxied, target);
  const result = new Proxy(proxied, MapHandler);
  return result;
}
function listProxy(context2, objectId, textV2, path) {
  const target = {
    context: context2,
    objectId,
    path: path || [],
    cache: {},
    textV2
  };
  const proxied = [];
  Object.assign(proxied, target);
  return new Proxy(proxied, ListHandler);
}
function textProxy(context2, objectId, path) {
  const target = {
    context: context2,
    objectId,
    path: path || [],
    cache: {},
    textV2: false
  };
  const proxied = {};
  Object.assign(proxied, target);
  return new Proxy(proxied, TextHandler);
}
function rootProxy(context2, textV2) {
  return mapProxy(context2, "_root", textV2, []);
}
function listMethods(target) {
  const { context: context2, objectId, path, textV2 } = target;
  const methods = {
    deleteAt(index, numDelete) {
      if (typeof numDelete === "number") {
        context2.splice(objectId, index, numDelete);
      } else {
        context2.delete(objectId, index);
      }
      return this;
    },
    fill(val, start, end) {
      const [value, datatype] = import_value(val, textV2, [...path, start]);
      const length = context2.length(objectId);
      start = parseListIndex(start || 0);
      end = parseListIndex(end || length);
      for (let i = start; i < Math.min(end, length); i++) {
        if (datatype === "list" || datatype === "map") {
          context2.putObject(objectId, i, value);
        } else if (datatype === "text") {
          if (textV2) {
            assertString(value);
            context2.putObject(objectId, i, value);
          } else {
            assertText(value);
            const text = context2.putObject(objectId, i, "");
            const proxyText = textProxy(context2, text, [...path, i]);
            for (let i2 = 0; i2 < value.length; i2++) {
              proxyText[i2] = value.get(i2);
            }
          }
        } else {
          context2.put(objectId, i, value, datatype);
        }
      }
      return this;
    },
    indexOf(o, start = 0) {
      const length = context2.length(objectId);
      for (let i = start; i < length; i++) {
        const value = context2.getWithType(objectId, i);
        if (value && (value[1] === o[OBJECT_ID] || value[1] === o)) {
          return i;
        }
      }
      return -1;
    },
    insertAt(index, ...values) {
      this.splice(index, 0, ...values);
      return this;
    },
    pop() {
      const length = context2.length(objectId);
      if (length == 0) {
        return void 0;
      }
      const last = valueAt(target, length - 1);
      context2.delete(objectId, length - 1);
      return last;
    },
    push(...values) {
      const len = context2.length(objectId);
      this.splice(len, 0, ...values);
      return context2.length(objectId);
    },
    shift() {
      if (context2.length(objectId) == 0)
        return;
      const first = valueAt(target, 0);
      context2.delete(objectId, 0);
      return first;
    },
    splice(index, del, ...vals) {
      index = parseListIndex(index);
      if (typeof del !== "number") {
        del = context2.length(objectId) - index;
      }
      del = parseListIndex(del);
      for (const val of vals) {
        if (isSameDocument(val, context2)) {
          throw new RangeError("Cannot create a reference to an existing document object");
        }
      }
      const result = [];
      for (let i = 0; i < del; i++) {
        const value = valueAt(target, index);
        if (value !== void 0) {
          result.push(value);
        }
        context2.delete(objectId, index);
      }
      const values = vals.map((val, index2) => {
        try {
          return import_value(val, textV2, [...path]);
        } catch (e) {
          if (e instanceof RangeError) {
            throw new RangeError(`${e.message} at index ${index2} in the input`);
          } else {
            throw e;
          }
        }
      });
      for (const [value, datatype] of values) {
        switch (datatype) {
          case "list": {
            const list = context2.insertObject(objectId, index, []);
            const proxyList = listProxy(context2, list, textV2, [...path, index]);
            proxyList.splice(0, 0, ...value);
            break;
          }
          case "text": {
            if (textV2) {
              assertString(value);
              context2.insertObject(objectId, index, value);
            } else {
              const text = context2.insertObject(objectId, index, "");
              const proxyText = textProxy(context2, text, [...path, index]);
              proxyText.splice(0, 0, ...value);
            }
            break;
          }
          case "map": {
            const map = context2.insertObject(objectId, index, {});
            const proxyMap = mapProxy(context2, map, textV2, [...path, index]);
            for (const key in value) {
              proxyMap[key] = value[key];
            }
            break;
          }
          default:
            context2.insert(objectId, index, value, datatype);
        }
        index += 1;
      }
      return result;
    },
    unshift(...values) {
      this.splice(0, 0, ...values);
      return context2.length(objectId);
    },
    entries() {
      let i = 0;
      const iterator = {
        next: () => {
          const value = valueAt(target, i);
          if (value === void 0) {
            return { value: void 0, done: true };
          } else {
            return { value: [i++, value], done: false };
          }
        },
        [Symbol.iterator]() {
          return this;
        }
      };
      return iterator;
    },
    keys() {
      let i = 0;
      const len = context2.length(objectId);
      const iterator = {
        next: () => {
          if (i < len) {
            return { value: i++, done: false };
          }
          return { value: void 0, done: true };
        },
        [Symbol.iterator]() {
          return this;
        }
      };
      return iterator;
    },
    values() {
      let i = 0;
      const iterator = {
        next: () => {
          const value = valueAt(target, i++);
          if (value === void 0) {
            return { value: void 0, done: true };
          } else {
            return { value, done: false };
          }
        },
        [Symbol.iterator]() {
          return this;
        }
      };
      return iterator;
    },
    toArray() {
      const list = [];
      let value;
      do {
        value = valueAt(target, list.length);
        if (value !== void 0) {
          list.push(value);
        }
      } while (value !== void 0);
      return list;
    },
    map(f) {
      return this.toArray().map(f);
    },
    toString() {
      return this.toArray().toString();
    },
    toLocaleString() {
      return this.toArray().toLocaleString();
    },
    forEach(f) {
      return this.toArray().forEach(f);
    },
    // todo: real concat function is different
    concat(other) {
      return this.toArray().concat(other);
    },
    every(f) {
      return this.toArray().every(f);
    },
    filter(f) {
      return this.toArray().filter(f);
    },
    find(f) {
      let index = 0;
      for (const v of this) {
        if (f(v, index)) {
          return v;
        }
        index += 1;
      }
    },
    findIndex(f) {
      let index = 0;
      for (const v of this) {
        if (f(v, index)) {
          return index;
        }
        index += 1;
      }
      return -1;
    },
    includes(elem) {
      return this.find((e) => e === elem) !== void 0;
    },
    join(sep) {
      return this.toArray().join(sep);
    },
    reduce(f, initialValue) {
      return this.toArray().reduce(f, initialValue);
    },
    reduceRight(f, initialValue) {
      return this.toArray().reduceRight(f, initialValue);
    },
    lastIndexOf(search, fromIndex = Infinity) {
      return this.toArray().lastIndexOf(search, fromIndex);
    },
    slice(index, num) {
      return this.toArray().slice(index, num);
    },
    some(f) {
      let index = 0;
      for (const v of this) {
        if (f(v, index)) {
          return true;
        }
        index += 1;
      }
      return false;
    },
    [Symbol.iterator]: function* () {
      let i = 0;
      let value = valueAt(target, i);
      while (value !== void 0) {
        yield value;
        i += 1;
        value = valueAt(target, i);
      }
    }
  };
  return methods;
}
function textMethods(target) {
  const { context: context2, objectId } = target;
  const methods = {
    set(index, value) {
      return this[index] = value;
    },
    get(index) {
      return this[index];
    },
    toString() {
      return context2.text(objectId).replace(/￼/g, "");
    },
    toSpans() {
      const spans2 = [];
      let chars = "";
      const length = context2.length(objectId);
      for (let i = 0; i < length; i++) {
        const value = this[i];
        if (typeof value === "string") {
          chars += value;
        } else {
          if (chars.length > 0) {
            spans2.push(chars);
            chars = "";
          }
          spans2.push(value);
        }
      }
      if (chars.length > 0) {
        spans2.push(chars);
      }
      return spans2;
    },
    toJSON() {
      return this.toString();
    },
    indexOf(o, start = 0) {
      const text = context2.text(objectId);
      return text.indexOf(o, start);
    },
    insertAt(index, ...values) {
      if (values.every((v) => typeof v === "string")) {
        context2.splice(objectId, index, 0, values.join(""));
      } else {
        listMethods(target).insertAt(index, ...values);
      }
    }
  };
  return methods;
}
function assertText(value) {
  if (!(value instanceof Text)) {
    throw new Error("value was not a Text instance");
  }
}
function assertString(value) {
  if (typeof value !== "string") {
    throw new Error("value was not a string");
  }
}
function printPath(path) {
  const jsonPointerComponents = path.map((component) => {
    if (typeof component === "number") {
      return component.toString();
    } else if (typeof component === "string") {
      return component.replace(/~/g, "~0").replace(/\//g, "~1");
    }
  });
  if (path.length === 0) {
    return "";
  } else {
    return "/" + jsonPointerComponents.join("/");
  }
}

// ../../../../node_modules/.pnpm/@automerge+automerge@2.2.2/node_modules/@automerge/automerge/dist/mjs/low_level.js
function UseApi(api) {
  for (const k in api) {
    ;
    ApiHandler[k] = api[k];
  }
}
var ApiHandler = {
  create(options) {
    throw new RangeError("Automerge.use() not called");
  },
  load(data, options) {
    throw new RangeError("Automerge.use() not called (load)");
  },
  encodeChange(change2) {
    throw new RangeError("Automerge.use() not called (encodeChange)");
  },
  decodeChange(change2) {
    throw new RangeError("Automerge.use() not called (decodeChange)");
  },
  initSyncState() {
    throw new RangeError("Automerge.use() not called (initSyncState)");
  },
  encodeSyncMessage(message) {
    throw new RangeError("Automerge.use() not called (encodeSyncMessage)");
  },
  decodeSyncMessage(msg) {
    throw new RangeError("Automerge.use() not called (decodeSyncMessage)");
  },
  encodeSyncState(state) {
    throw new RangeError("Automerge.use() not called (encodeSyncState)");
  },
  decodeSyncState(data) {
    throw new RangeError("Automerge.use() not called (decodeSyncState)");
  },
  exportSyncState(state) {
    throw new RangeError("Automerge.use() not called (exportSyncState)");
  },
  importSyncState(state) {
    throw new RangeError("Automerge.use() not called (importSyncState)");
  }
};

// ../../../../node_modules/.pnpm/@automerge+automerge@2.2.2/node_modules/@automerge/automerge/dist/mjs/internal_state.js
function _state(doc, checkroot = true) {
  if (typeof doc !== "object") {
    throw new RangeError("must be the document root");
  }
  const state = Reflect.get(doc, STATE);
  if (state === void 0 || state == null || checkroot && _obj(doc) !== "_root") {
    throw new RangeError("must be the document root");
  }
  return state;
}
function _clear_cache(doc) {
  Reflect.set(doc, CLEAR_CACHE, true);
}
function _trace(doc) {
  return Reflect.get(doc, TRACE);
}
function _obj(doc) {
  if (!(typeof doc === "object") || doc === null) {
    return null;
  }
  return Reflect.get(doc, OBJECT_ID);
}
function _is_proxy(doc) {
  return !!Reflect.get(doc, IS_PROXY);
}

// ../../../../node_modules/.pnpm/@automerge+automerge@2.2.2/node_modules/@automerge/automerge/dist/mjs/conflicts.js
function stableConflictAt(context2, objectId, prop) {
  return conflictAt(context2, objectId, prop, true, (context3, conflictId) => {
    return new Text(context3.text(conflictId));
  });
}
function unstableConflictAt(context2, objectId, prop) {
  return conflictAt(context2, objectId, prop, true, (context3, conflictId) => {
    return context3.text(conflictId);
  });
}
function conflictAt(context2, objectId, prop, textV2, handleText) {
  const values = context2.getAll(objectId, prop);
  if (values.length <= 1) {
    return;
  }
  const result = {};
  for (const fullVal of values) {
    switch (fullVal[0]) {
      case "map":
        result[fullVal[1]] = mapProxy(context2, fullVal[1], textV2, [prop]);
        break;
      case "list":
        result[fullVal[1]] = listProxy(context2, fullVal[1], textV2, [prop]);
        break;
      case "text":
        result[fullVal[1]] = handleText(context2, fullVal[1]);
        break;
      case "str":
      case "uint":
      case "int":
      case "f64":
      case "boolean":
      case "bytes":
      case "null":
        result[fullVal[2]] = fullVal[1];
        break;
      case "counter":
        result[fullVal[2]] = new Counter(fullVal[1]);
        break;
      case "timestamp":
        result[fullVal[2]] = new Date(fullVal[1]);
        break;
      default:
        throw RangeError(`datatype ${fullVal[0]} unimplemented`);
    }
  }
  return result;
}

// ../../../../node_modules/.pnpm/@automerge+automerge@2.2.2/node_modules/@automerge/automerge/dist/mjs/stable.js
import * as wasm from "#automerge-wasm";
var __rest = function(s, e) {
  var t = {};
  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
    t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function")
    for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
      if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
        t[p[i]] = s[p[i]];
    }
  return t;
};
var SyncStateSymbol = Symbol("_syncstate");
function insertAt(list, index, ...values) {
  if (!_is_proxy(list)) {
    throw new RangeError("object cannot be modified outside of a change block");
  }
  ;
  list.insertAt(index, ...values);
}
function deleteAt(list, index, numDelete) {
  if (!_is_proxy(list)) {
    throw new RangeError("object cannot be modified outside of a change block");
  }
  ;
  list.deleteAt(index, numDelete);
}
function use(api) {
  UseApi(api);
}
use(wasm);
function getBackend(doc) {
  return _state(doc).handle;
}
function importOpts(_actor) {
  if (typeof _actor === "object") {
    return _actor;
  } else {
    return { actor: _actor };
  }
}
function init(_opts) {
  const opts = importOpts(_opts);
  const freeze = !!opts.freeze;
  const patchCallback = opts.patchCallback;
  const text_v1 = !(opts.enableTextV2 || false);
  const actor = opts.actor;
  const handle = ApiHandler.create({ actor, text_v1 });
  handle.enableFreeze(!!opts.freeze);
  const textV2 = opts.enableTextV2 || false;
  registerDatatypes(handle, textV2);
  const doc = handle.materialize("/", void 0, {
    handle,
    heads: void 0,
    freeze,
    patchCallback,
    textV2
  });
  return doc;
}
function view(doc, heads) {
  const state = _state(doc);
  const handle = state.handle;
  return state.handle.materialize("/", heads, Object.assign(Object.assign({}, state), {
    handle,
    heads
  }));
}
function clone(doc, _opts) {
  const state = _state(doc);
  const heads = state.heads;
  const opts = importOpts(_opts);
  const handle = state.handle.fork(opts.actor, heads);
  handle.updateDiffCursor();
  const { heads: _oldHeads } = state, stateSansHeads = __rest(state, ["heads"]);
  stateSansHeads.patchCallback = opts.patchCallback;
  return handle.applyPatches(doc, Object.assign(Object.assign({}, stateSansHeads), { handle }));
}
function free(doc) {
  return _state(doc).handle.free();
}
function from(initialState, _opts) {
  return _change(init(_opts), "from", {}, (d) => Object.assign(d, initialState)).newDoc;
}
function change(doc, options, callback) {
  if (typeof options === "function") {
    return _change(doc, "change", {}, options).newDoc;
  } else if (typeof callback === "function") {
    if (typeof options === "string") {
      options = { message: options };
    }
    return _change(doc, "change", options, callback).newDoc;
  } else {
    throw RangeError("Invalid args for change");
  }
}
function changeAt(doc, scope, options, callback) {
  if (typeof options === "function") {
    return _change(doc, "changeAt", {}, options, scope);
  } else if (typeof callback === "function") {
    if (typeof options === "string") {
      options = { message: options };
    }
    return _change(doc, "changeAt", options, callback, scope);
  } else {
    throw RangeError("Invalid args for changeAt");
  }
}
function progressDocument(doc, source, heads, callback) {
  if (heads == null) {
    return doc;
  }
  const state = _state(doc);
  const nextState = Object.assign(Object.assign({}, state), { heads: void 0 });
  const { value: nextDoc, patches } = state.handle.applyAndReturnPatches(doc, nextState);
  if (patches.length > 0) {
    if (callback != null) {
      callback(patches, { before: doc, after: nextDoc, source });
    }
    const newState = _state(nextDoc);
    newState.mostRecentPatch = {
      before: _state(doc).heads,
      after: newState.handle.getHeads(),
      patches,
      source
    };
  }
  state.heads = heads;
  return nextDoc;
}
function _change(doc, source, options, callback, scope) {
  if (typeof callback !== "function") {
    throw new RangeError("invalid change function");
  }
  const state = _state(doc);
  if (doc === void 0 || state === void 0) {
    throw new RangeError("must be the document root");
  }
  if (state.heads) {
    throw new RangeError("Attempting to change an outdated document.  Use Automerge.clone() if you wish to make a writable copy.");
  }
  if (_is_proxy(doc)) {
    throw new RangeError("Calls to Automerge.change cannot be nested");
  }
  let heads = state.handle.getHeads();
  if (scope && headsEqual(scope, heads)) {
    scope = void 0;
  }
  if (scope) {
    state.handle.isolate(scope);
    heads = scope;
  }
  try {
    state.heads = heads;
    const root = rootProxy(state.handle, state.textV2);
    callback(root);
    if (state.handle.pendingOps() === 0) {
      state.heads = void 0;
      if (scope) {
        state.handle.integrate();
      }
      return {
        newDoc: doc,
        newHeads: null
      };
    } else {
      const newHead = state.handle.commit(options.message, options.time);
      state.handle.integrate();
      return {
        newDoc: progressDocument(doc, source, heads, options.patchCallback || state.patchCallback),
        newHeads: newHead != null ? [newHead] : null
      };
    }
  } catch (e) {
    state.heads = void 0;
    state.handle.rollback();
    throw e;
  }
}
function emptyChange(doc, options) {
  if (options === void 0) {
    options = {};
  }
  if (typeof options === "string") {
    options = { message: options };
  }
  const state = _state(doc);
  if (state.heads) {
    throw new RangeError("Attempting to change an outdated document.  Use Automerge.clone() if you wish to make a writable copy.");
  }
  if (_is_proxy(doc)) {
    throw new RangeError("Calls to Automerge.change cannot be nested");
  }
  const heads = state.handle.getHeads();
  state.handle.emptyChange(options.message, options.time);
  return progressDocument(doc, "emptyChange", heads);
}
function load(data, _opts) {
  const opts = importOpts(_opts);
  const actor = opts.actor;
  const patchCallback = opts.patchCallback;
  const text_v1 = !(opts.enableTextV2 || false);
  const unchecked = opts.unchecked || false;
  const allowMissingDeps = opts.allowMissingChanges || false;
  const convertRawStringsToText = opts.convertRawStringsToText || false;
  const handle = ApiHandler.load(data, {
    text_v1,
    actor,
    unchecked,
    allowMissingDeps,
    convertRawStringsToText
  });
  handle.enableFreeze(!!opts.freeze);
  const textV2 = opts.enableTextV2 || false;
  registerDatatypes(handle, textV2);
  const doc = handle.materialize("/", void 0, {
    handle,
    heads: void 0,
    patchCallback,
    textV2
  });
  return doc;
}
function loadIncremental(doc, data, opts) {
  if (!opts) {
    opts = {};
  }
  const state = _state(doc);
  if (state.heads) {
    throw new RangeError("Attempting to change an out of date document - set at: " + _trace(doc));
  }
  if (_is_proxy(doc)) {
    throw new RangeError("Calls to Automerge.change cannot be nested");
  }
  const heads = state.handle.getHeads();
  state.handle.loadIncremental(data);
  return progressDocument(doc, "loadIncremental", heads, opts.patchCallback || state.patchCallback);
}
function saveIncremental(doc) {
  const state = _state(doc);
  if (state.heads) {
    throw new RangeError("Attempting to change an out of date document - set at: " + _trace(doc));
  }
  if (_is_proxy(doc)) {
    throw new RangeError("Calls to Automerge.change cannot be nested");
  }
  return state.handle.saveIncremental();
}
function save(doc) {
  return _state(doc).handle.save();
}
function merge(local, remote) {
  const localState = _state(local);
  if (localState.heads) {
    throw new RangeError("Attempting to change an out of date document - set at: " + _trace(local));
  }
  const heads = localState.handle.getHeads();
  const remoteState = _state(remote);
  const changes = localState.handle.getChangesAdded(remoteState.handle);
  localState.handle.applyChanges(changes);
  return progressDocument(local, "merge", heads, localState.patchCallback);
}
function getActorId(doc) {
  const state = _state(doc);
  return state.handle.getActorId();
}
function getConflicts(doc, prop) {
  const state = _state(doc, false);
  if (state.textV2) {
    throw new Error("use unstable.getConflicts for an unstable document");
  }
  const objectId = _obj(doc);
  if (objectId != null) {
    return stableConflictAt(state.handle, objectId, prop);
  } else {
    return void 0;
  }
}
function getLastLocalChange(doc) {
  const state = _state(doc);
  return state.handle.getLastLocalChange() || void 0;
}
function getObjectId(doc, prop) {
  if (prop) {
    const state = _state(doc, false);
    const objectId = _obj(doc);
    if (!state || !objectId) {
      return null;
    }
    return state.handle.get(objectId, prop);
  } else {
    return _obj(doc);
  }
}
function getChanges(oldState, newState) {
  const n = _state(newState);
  return n.handle.getChanges(getHeads(oldState));
}
function getAllChanges(doc) {
  const state = _state(doc);
  return state.handle.getChanges([]);
}
function applyChanges(doc, changes, opts) {
  const state = _state(doc);
  if (!opts) {
    opts = {};
  }
  if (state.heads) {
    throw new RangeError("Attempting to change an outdated document.  Use Automerge.clone() if you wish to make a writable copy.");
  }
  if (_is_proxy(doc)) {
    throw new RangeError("Calls to Automerge.change cannot be nested");
  }
  const heads = state.handle.getHeads();
  state.handle.applyChanges(changes);
  state.heads = heads;
  return [
    progressDocument(doc, "applyChanges", heads, opts.patchCallback || state.patchCallback)
  ];
}
function getHistory(doc) {
  const textV2 = _state(doc).textV2;
  const history = getAllChanges(doc);
  return history.map((change2, index) => ({
    get change() {
      return decodeChange(change2);
    },
    get snapshot() {
      const [state] = applyChanges(init({ enableTextV2: textV2 }), history.slice(0, index + 1));
      return state;
    }
  }));
}
function diff(doc, before, after) {
  checkHeads(before, "before");
  checkHeads(after, "after");
  const state = _state(doc);
  if (state.mostRecentPatch && equals(state.mostRecentPatch.before, before) && equals(state.mostRecentPatch.after, after)) {
    return state.mostRecentPatch.patches;
  }
  return state.handle.diff(before, after);
}
function headsEqual(heads1, heads2) {
  if (heads1.length !== heads2.length) {
    return false;
  }
  for (let i = 0; i < heads1.length; i++) {
    if (heads1[i] !== heads2[i]) {
      return false;
    }
  }
  return true;
}
function checkHeads(heads, fieldname) {
  if (!Array.isArray(heads)) {
    throw new Error(`${fieldname} must be an array`);
  }
}
function equals(val1, val2) {
  if (!isObject(val1) || !isObject(val2))
    return val1 === val2;
  const keys1 = Object.keys(val1).sort(), keys2 = Object.keys(val2).sort();
  if (keys1.length !== keys2.length)
    return false;
  for (let i = 0; i < keys1.length; i++) {
    if (keys1[i] !== keys2[i])
      return false;
    if (!equals(val1[keys1[i]], val2[keys2[i]]))
      return false;
  }
  return true;
}
function encodeSyncState(state) {
  const sync = ApiHandler.importSyncState(state);
  const result = ApiHandler.encodeSyncState(sync);
  sync.free();
  return result;
}
function decodeSyncState(state) {
  const sync = ApiHandler.decodeSyncState(state);
  const result = ApiHandler.exportSyncState(sync);
  sync.free();
  return result;
}
function generateSyncMessage(doc, inState) {
  const state = _state(doc);
  const syncState = ApiHandler.importSyncState(inState);
  const message = state.handle.generateSyncMessage(syncState);
  const outState = ApiHandler.exportSyncState(syncState);
  return [outState, message];
}
function receiveSyncMessage(doc, inState, message, opts) {
  const syncState = ApiHandler.importSyncState(inState);
  if (!opts) {
    opts = {};
  }
  const state = _state(doc);
  if (state.heads) {
    throw new RangeError("Attempting to change an outdated document.  Use Automerge.clone() if you wish to make a writable copy.");
  }
  if (_is_proxy(doc)) {
    throw new RangeError("Calls to Automerge.change cannot be nested");
  }
  const heads = state.handle.getHeads();
  state.handle.receiveSyncMessage(syncState, message);
  const outSyncState = ApiHandler.exportSyncState(syncState);
  return [
    progressDocument(doc, "receiveSyncMessage", heads, opts.patchCallback || state.patchCallback),
    outSyncState,
    null
  ];
}
function initSyncState() {
  return ApiHandler.exportSyncState(ApiHandler.initSyncState());
}
function encodeChange(change2) {
  return ApiHandler.encodeChange(change2);
}
function decodeChange(data) {
  return ApiHandler.decodeChange(data);
}
function encodeSyncMessage(message) {
  return ApiHandler.encodeSyncMessage(message);
}
function decodeSyncMessage(message) {
  return ApiHandler.decodeSyncMessage(message);
}
function getMissingDeps(doc, heads) {
  const state = _state(doc);
  return state.handle.getMissingDeps(heads);
}
function getHeads(doc) {
  const state = _state(doc);
  return state.heads || state.handle.getHeads();
}
function dump(doc) {
  const state = _state(doc);
  state.handle.dump();
}
function toJS(doc) {
  const state = _state(doc);
  const enabled = state.handle.enableFreeze(false);
  const result = state.handle.materialize();
  state.handle.enableFreeze(enabled);
  return result;
}
function isAutomerge(doc) {
  if (typeof doc == "object" && doc !== null) {
    return getObjectId(doc) === "_root" && !!Reflect.get(doc, STATE);
  } else {
    return false;
  }
}
function isObject(obj) {
  return typeof obj === "object" && obj !== null;
}
function saveSince(doc, heads) {
  const state = _state(doc);
  const result = state.handle.saveSince(heads);
  return result;
}
function registerDatatypes(handle, textV2) {
  handle.registerDatatype("counter", (n) => new Counter(n), (n) => {
    if (n instanceof Counter) {
      return n.value;
    }
  });
  if (textV2) {
    handle.registerDatatype("str", (n) => {
      return new RawString(n);
    }, (s) => {
      if (s instanceof RawString) {
        return s.val;
      }
    });
  } else {
    handle.registerDatatype("text", (n) => new Text(n), (t) => {
      if (t instanceof Text) {
        return t.join("");
      }
    });
  }
}

// ../../../../node_modules/.pnpm/@automerge+automerge@2.2.2/node_modules/@automerge/automerge/dist/mjs/next.js
var next_exports = {};
__export(next_exports, {
  Counter: () => Counter,
  Float64: () => Float64,
  Int: () => Int,
  RawString: () => RawString,
  Uint: () => Uint,
  applyChanges: () => applyChanges,
  block: () => block,
  change: () => change,
  changeAt: () => changeAt,
  clone: () => clone2,
  decodeChange: () => decodeChange,
  decodeSyncMessage: () => decodeSyncMessage,
  decodeSyncState: () => decodeSyncState,
  deleteAt: () => deleteAt,
  diff: () => diff,
  dump: () => dump,
  emptyChange: () => emptyChange,
  encodeChange: () => encodeChange,
  encodeSyncMessage: () => encodeSyncMessage,
  encodeSyncState: () => encodeSyncState,
  equals: () => equals,
  free: () => free,
  from: () => from2,
  generateSyncMessage: () => generateSyncMessage,
  getActorId: () => getActorId,
  getAllChanges: () => getAllChanges,
  getBackend: () => getBackend2,
  getChanges: () => getChanges,
  getConflicts: () => getConflicts2,
  getCursor: () => getCursor,
  getCursorPosition: () => getCursorPosition,
  getHeads: () => getHeads,
  getHistory: () => getHistory,
  getLastLocalChange: () => getLastLocalChange,
  getMissingDeps: () => getMissingDeps,
  getObjectId: () => getObjectId,
  init: () => init2,
  initSyncState: () => initSyncState,
  insertAt: () => insertAt,
  isAutomerge: () => isAutomerge,
  joinBlock: () => joinBlock,
  load: () => load2,
  loadIncremental: () => loadIncremental,
  mark: () => mark,
  marks: () => marks,
  marksAt: () => marksAt,
  merge: () => merge,
  receiveSyncMessage: () => receiveSyncMessage,
  save: () => save,
  saveIncremental: () => saveIncremental,
  saveSince: () => saveSince,
  spans: () => spans,
  splice: () => splice,
  splitBlock: () => splitBlock,
  toJS: () => toJS,
  unmark: () => unmark,
  updateBlock: () => updateBlock,
  updateSpans: () => updateSpans,
  updateText: () => updateText,
  view: () => view
});
var getBackend2 = getBackend;
function init2(_opts) {
  const opts = importOpts2(_opts);
  opts.enableTextV2 = true;
  return init(opts);
}
function clone2(doc, _opts) {
  const opts = importOpts2(_opts);
  opts.enableTextV2 = true;
  return clone(doc, opts);
}
function from2(initialState, _opts) {
  const opts = importOpts2(_opts);
  opts.enableTextV2 = true;
  return from(initialState, opts);
}
function load2(data, _opts) {
  const opts = importOpts2(_opts);
  opts.enableTextV2 = true;
  if (opts.patchCallback) {
    return loadIncremental(init(opts), data);
  } else {
    return load(data, opts);
  }
}
function importOpts2(_actor) {
  if (typeof _actor === "object") {
    return _actor;
  } else {
    return { actor: _actor };
  }
}
function cursorToIndex(state, value, index) {
  if (typeof index == "string") {
    if (/^[0-9]+@[0-9a-zA-z]+$/.test(index)) {
      return state.handle.getCursorPosition(value, index);
    } else {
      throw new RangeError("index must be a number or cursor");
    }
  } else {
    return index;
  }
}
function splice(doc, path, index, del, newText) {
  const objPath = absoluteObjPath(doc, path, "splice");
  if (!_is_proxy(doc)) {
    throw new RangeError("object cannot be modified outside of a change block");
  }
  const state = _state(doc, false);
  _clear_cache(doc);
  index = cursorToIndex(state, objPath, index);
  try {
    return state.handle.splice(objPath, index, del, newText);
  } catch (e) {
    throw new RangeError(`Cannot splice: ${e}`);
  }
}
function updateText(doc, path, newText) {
  const objPath = absoluteObjPath(doc, path, "updateText");
  if (!_is_proxy(doc)) {
    throw new RangeError("object cannot be modified outside of a change block");
  }
  const state = _state(doc, false);
  _clear_cache(doc);
  try {
    return state.handle.updateText(objPath, newText);
  } catch (e) {
    throw new RangeError(`Cannot updateText: ${e}`);
  }
}
function spans(doc, path) {
  const state = _state(doc, false);
  const objPath = absoluteObjPath(doc, path, "spans");
  try {
    return state.handle.spans(objPath, state.heads);
  } catch (e) {
    throw new RangeError(`Cannot splice: ${e}`);
  }
}
function block(doc, path, index) {
  const objPath = absoluteObjPath(doc, path, "splitBlock");
  const state = _state(doc, false);
  index = cursorToIndex(state, objPath, index);
  try {
    return state.handle.getBlock(objPath, index);
  } catch (e) {
    throw new RangeError(`Cannot get block: ${e}`);
  }
}
function splitBlock(doc, path, index, block2) {
  if (!_is_proxy(doc)) {
    throw new RangeError("object cannot be modified outside of a change block");
  }
  const objPath = absoluteObjPath(doc, path, "splitBlock");
  const state = _state(doc, false);
  _clear_cache(doc);
  index = cursorToIndex(state, objPath, index);
  try {
    state.handle.splitBlock(objPath, index, block2);
  } catch (e) {
    throw new RangeError(`Cannot splice: ${e}`);
  }
}
function joinBlock(doc, path, index) {
  if (!_is_proxy(doc)) {
    throw new RangeError("object cannot be modified outside of a change block");
  }
  const objPath = absoluteObjPath(doc, path, "joinBlock");
  const state = _state(doc, false);
  _clear_cache(doc);
  index = cursorToIndex(state, objPath, index);
  try {
    state.handle.joinBlock(objPath, index);
  } catch (e) {
    throw new RangeError(`Cannot joinBlock: ${e}`);
  }
}
function updateBlock(doc, path, index, block2) {
  if (!_is_proxy(doc)) {
    throw new RangeError("object cannot be modified outside of a change block");
  }
  const objPath = absoluteObjPath(doc, path, "updateBlock");
  const state = _state(doc, false);
  _clear_cache(doc);
  index = cursorToIndex(state, objPath, index);
  try {
    state.handle.updateBlock(objPath, index, block2);
  } catch (e) {
    throw new RangeError(`Cannot updateBlock: ${e}`);
  }
}
function updateSpans(doc, path, newSpans) {
  if (!_is_proxy(doc)) {
    throw new RangeError("object cannot be modified outside of a change block");
  }
  const objPath = absoluteObjPath(doc, path, "updateSpans");
  const state = _state(doc, false);
  _clear_cache(doc);
  try {
    state.handle.updateSpans(objPath, newSpans);
  } catch (e) {
    throw new RangeError(`Cannot updateBlock: ${e}`);
  }
}
function getCursor(doc, path, index) {
  const objPath = absoluteObjPath(doc, path, "getCursor");
  const state = _state(doc, false);
  try {
    return state.handle.getCursor(objPath, index);
  } catch (e) {
    throw new RangeError(`Cannot getCursor: ${e}`);
  }
}
function getCursorPosition(doc, path, cursor) {
  const objPath = absoluteObjPath(doc, path, "getCursorPosition");
  const state = _state(doc, false);
  try {
    return state.handle.getCursorPosition(objPath, cursor);
  } catch (e) {
    throw new RangeError(`Cannot getCursorPosition: ${e}`);
  }
}
function mark(doc, path, range, name, value) {
  const objPath = absoluteObjPath(doc, path, "mark");
  if (!_is_proxy(doc)) {
    throw new RangeError("object cannot be modified outside of a change block");
  }
  const state = _state(doc, false);
  try {
    return state.handle.mark(objPath, range, name, value);
  } catch (e) {
    throw new RangeError(`Cannot mark: ${e}`);
  }
}
function unmark(doc, path, range, name) {
  const objPath = absoluteObjPath(doc, path, "unmark");
  if (!_is_proxy(doc)) {
    throw new RangeError("object cannot be modified outside of a change block");
  }
  const state = _state(doc, false);
  try {
    return state.handle.unmark(objPath, range, name);
  } catch (e) {
    throw new RangeError(`Cannot unmark: ${e}`);
  }
}
function marks(doc, path) {
  const objPath = absoluteObjPath(doc, path, "marks");
  const state = _state(doc, false);
  try {
    return state.handle.marks(objPath);
  } catch (e) {
    throw new RangeError(`Cannot call marks(): ${e}`);
  }
}
function marksAt(doc, path, index) {
  const objPath = absoluteObjPath(doc, path, "marksAt");
  const state = _state(doc, false);
  try {
    return state.handle.marksAt(objPath, index);
  } catch (e) {
    throw new RangeError(`Cannot call marksAt(): ${e}`);
  }
}
function getConflicts2(doc, prop) {
  const state = _state(doc, false);
  if (!state.textV2) {
    throw new Error("use getConflicts for a stable document");
  }
  const objectId = _obj(doc);
  if (objectId != null) {
    return unstableConflictAt(state.handle, objectId, prop);
  } else {
    return void 0;
  }
}
function absoluteObjPath(doc, path, functionName) {
  path = path.slice();
  const objectId = _obj(doc);
  if (!objectId) {
    throw new RangeError(`invalid object for ${functionName}`);
  }
  path.unshift(objectId);
  return path.join("/");
}

export {
  uuid,
  Text,
  Counter,
  RawString,
  Int,
  Uint,
  Float64,
  insertAt,
  deleteAt,
  use,
  getBackend,
  init,
  view,
  clone,
  free,
  from,
  change,
  changeAt,
  emptyChange,
  load,
  loadIncremental,
  saveIncremental,
  save,
  merge,
  getActorId,
  getConflicts,
  getLastLocalChange,
  getObjectId,
  getChanges,
  getAllChanges,
  applyChanges,
  getHistory,
  diff,
  equals,
  encodeSyncState,
  decodeSyncState,
  generateSyncMessage,
  receiveSyncMessage,
  initSyncState,
  encodeChange,
  decodeChange,
  encodeSyncMessage,
  decodeSyncMessage,
  getMissingDeps,
  getHeads,
  dump,
  toJS,
  isAutomerge,
  saveSince,
  getBackend2,
  init2,
  clone2,
  from2,
  load2,
  splice,
  updateText,
  spans,
  block,
  splitBlock,
  joinBlock,
  updateBlock,
  updateSpans,
  getCursor,
  getCursorPosition,
  mark,
  unmark,
  marks,
  marksAt,
  getConflicts2,
  next_exports
};
