//
// Copyright 2020 DXOS.org
//

// @generated by protoc-gen-es v2.1.0 with parameter "target=ts,import_extension=js"
// @generated from file dxos/keys.proto (package dxos.keys, syntax proto3)
/* eslint-disable */

import type { GenFile, GenMessage } from "@bufbuild/protobuf/codegenv1";
import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";
import type { Message } from "@bufbuild/protobuf";

/**
 * Describes the file dxos/keys.proto.
 */
export const file_dxos_keys: GenFile = /*@__PURE__*/
  fileDesc("Cg9keG9zL2tleXMucHJvdG8SCWR4b3Mua2V5cyIZCglQdWJsaWNLZXkSDAoEZGF0YRgBIAEoDCIaCgpQcml2YXRlS2V5EgwKBGRhdGEYASABKAxCKlooZ2l0aHViLmNvbS9keG9zL2R4b3MvcHJvdG8vZGVmL2R4b3Mva2V5c2IGcHJvdG8z");

/**
 * @generated from message dxos.keys.PublicKey
 */
export type PublicKey = Message<"dxos.keys.PublicKey"> & {
  /**
   * @generated from field: bytes data = 1;
   */
  data: Uint8Array;
};

/**
 * Describes the message dxos.keys.PublicKey.
 * Use `create(PublicKeySchema)` to create a new message.
 */
export const PublicKeySchema: GenMessage<PublicKey> = /*@__PURE__*/
  messageDesc(file_dxos_keys, 0);

/**
 * @generated from message dxos.keys.PrivateKey
 */
export type PrivateKey = Message<"dxos.keys.PrivateKey"> & {
  /**
   * @generated from field: bytes data = 1;
   */
  data: Uint8Array;
};

/**
 * Describes the message dxos.keys.PrivateKey.
 * Use `create(PrivateKeySchema)` to create a new message.
 */
export const PrivateKeySchema: GenMessage<PrivateKey> = /*@__PURE__*/
  messageDesc(file_dxos_keys, 1);

